import Vue from "vue";
import App from "./App.vue";

import Element from "element-ui";
import "@/styles/element-variables.scss";
import "@/styles/index.scss";
import "@/assets/css/font.css";
import "@/icons";
// import * as echarts from 'echarts';
import "@/utils/directives";
// import 'echarts/map/js/china.js';
// echarts.registerMap('china', china)
// Vue.prototype.$echarts = echarts

var needLoadingRequestCount = 0; //当前正在请求的数量

import router from "./router";
import store from "./store";
import "@/api";

import { keepTwo } from "./utils/filters";
Vue.prototype.$keepTwo = keepTwo;

// 时间格式化
import Moment from "moment";
Vue.prototype.moment = Moment;

// 拷贝
import VueClipboard from "vue-clipboard2";
VueClipboard.config.autoSetContainer = true;
Vue.use(VueClipboard);

import * as filters from "./filters"; // 全局过滤器
// 遍历所有全局过滤方法
Object.keys(filters).forEach((key) => {
  Vue.filter(key, filters[key]);
});

Vue.use(Element);

Vue.config.productionTip = false;
Vue.config.needLoadingRequestCount = 0;

new Vue({
  render: (h) => h(App),
  router,
  store,
}).$mount("#app");
