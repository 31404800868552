/*
 * @Description:
 * @Author: cuncai.zhang@simceredx.com
 * @Date: 2023-06-16 16:48:08
 * @LastEditTime: 2023-06-25 14:37:25
 * @LastEditors: cuncai.zhang@simceredx.com
 */
import Vue from "vue";
import router, { staticRoutes, dynamicRoutes, filterRoute } from "@/router";
import { transformUserPermissions } from "@/utils";
// var routeObj = [...dynamicRoutes]

const state = {
  token: sessionStorage.getItem("token") || "",
  userInfo: JSON.parse(sessionStorage.getItem("userInfo")) || {},
  // 菜单列表
  menuList: JSON.parse(sessionStorage.getItem("menu")) || [],
};

const mutations = {
  // 存储token
  SET_TOKEN: (state, token) => {
    state.token = token;
    sessionStorage.setItem("token", token);
  },
  // 存储用户信息
  SET_USER_INFO: (state, userInfo) => {
    state.userInfo = userInfo;
    sessionStorage.setItem("userInfo", JSON.stringify(userInfo));
  },
  // 设置菜单列表
  SET_SIDEBAR_LIST: (state, list) => {
    state.menuList = list;
  },
};

const actions = {
  // 存储用户信息
  setUserInfo({ commit, dispatch }, userInfo) {
    // 用户名头像
    commit("SET_USER_INFO", {
      avatar:
        "https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fup.enterdesk.com%2F2021%2Fedpic%2Ff1%2F88%2F71%2Ff1887154048414c4134e8bf7a3262a19_1.jpg&refer=http%3A%2F%2Fup.enterdesk.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=jpeg?sec=1637133950&t=d45d1500f8eeb95e8ed4fbfe5c055968",
      name: userInfo.username,
      authIds: userInfo.authIds,
      setTargetPermissions: userInfo.setTargetPermissions,
      personnelIdentity: userInfo.personnelIdentity,
      authDoctor: transformUserPermissions(userInfo.authDoctor),
      marketDataScope: userInfo.marketDataScope,
    });
    // 根据用户权限设置路由和菜单列表
    dispatch("setSidebarList", userInfo.authIds?.split(","));
  },
  // 登录
  login({ commit, dispatch }, userInfo) {
    const { username, password } = userInfo;
    return new Promise((resolve, reject) => {
      Vue.axios
        .post("LOGIN", { username: username.trim(), password: password }, false)
        .then((result) => {
          commit("SET_TOKEN", result.data.token);
          commit("SET_USER_INFO", {
            avatar:
              "https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fup.enterdesk.com%2F2021%2Fedpic%2Ff1%2F88%2F71%2Ff1887154048414c4134e8bf7a3262a19_1.jpg&refer=http%3A%2F%2Fup.enterdesk.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=jpeg?sec=1637133950&t=d45d1500f8eeb95e8ed4fbfe5c055968",
            name: result.data.username,
            personnelIdentity: result.data.personnelIdentity,
            marketDataScope: userInfo.marketDataScope,
          });
          resolve();
          // 根据用户权限设置路由和菜单列表
          dispatch("setSidebarList", userInfo.authIds?.split(","));
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // 根据用户权限设置路由和菜单列表
  setSidebarList({ commit }, menu) {
    console.log("menu", menu);
    let obj = [];
    let tempArr = obj.concat(dynamicRoutes);
    let route = filterRoute(tempArr, menu);
    console.log("route", route);
    staticRoutes.length = 0;
    commit("SET_SIDEBAR_LIST", staticRoutes.concat(route));
    //动态添加路由
    router.addRoutes(route);
    // 缓存菜单到本地
    sessionStorage.setItem("menu", JSON.stringify(staticRoutes.concat(route)));
  },
  // 退出登录
  logout({ commit, dispatch }) {
    commit("SET_TOKEN", "");
    commit("SET_USER_INFO", {});
    commit("SET_SIDEBAR_LIST", []);
    dispatch("tagsView/clearAll", {}, { root: true });
    sessionStorage.clear();
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
