import Vue from "vue";
import axios from "axios";
import urlEnum from "./urlEnum";
import store from "@/store";
import QS from "qs";

import { MessageBox, Message } from "element-ui";
// console.log('VUE_APP_SERVER_URL-------'+ process.env.VUE_APP_SERVER_URL)
// 请求基地址
let config = {
  // baseURL:process.env.NODE_ENV === "development" ? "/datascreen": " https://lsd.yoaimedicine.com/",
  // baseURL:
  //   process.env.NODE_ENV === "development"
  //     ? "/datascreen"
  //     : "http://test.lsd.yoaimedicine.com/",
  timeout: 3 * 60 * 1000,
  showLoading: true,
};

// 创建
const service = axios.create(config);

// 请求拦截
service.interceptors.request.use(
  (config) => {
    // 如果配置了携带token才携带
    if (config.isToken) {
      config.headers["token"] = window.sessionStorage.getItem("token");
      // config.headers["token"] =
      //   "a/PRIJraHppOYfn+2a1CYl4BrhyNGEohYvtb4C9FoAz5BfhBmb4q2RSVA4SUh6Xt22hXR2oRlBJiageeZFBY9Q==";

      // config.responseType = "blob"
    }
    if (
      config?.url.indexOf("/download") != -1 ||
      config?.url.indexOf("/export2") != -1 ||
      config?.url.indexOf("/export") != -1
    ) {
      config.responseType = "blob";
    }
    // console.log(config)
    return config;
  },
  (error) => {
    hideLoading();
    return Promise.reject(error);
  }
);

// 响应拦截
service.interceptors.response.use(
  //每次发送一个请求就进行++
  (response) => {
    hideLoading();
    const res = response.data;
    if (res.code === 1) {
      Message.error(res.msg);
    }
    if (res.code === 2100) {
      process.env.NODE_ENV === "development"
        ? ""
        : window.location.replace(res.data);
    }
    return res;
  },
  (error) => {
    hideLoading();
    if (error && error.response && error.response.data) {
      // 防止重复出现
      if (document.getElementsByClassName("el-message").length == 0) {
        /*Message({
          message: error.response.data.error,
          type: 'error',
          duration: 2 * 1000
        })*/
      }
      if (error.response.data.errorCode === 8936) {
        // 防止重复出现
        if (document.getElementsByClassName("el-message-box").length == 0) {
          MessageBox.confirm("Token已过期，请重新登录。", "确定登出", {
            confirmButtonText: "重新登录",
            cancelButtonText: "取消",
            type: "warning",
          }).then(() => {
            store.dispatch("user/logout");
          });
        }
      }
    }
    return Promise.reject(error);
  }
);

// 在axios上封装一层可以匹配路由地址的功能
let _axios = {
  // get请求,默认携带token,isToken传false不携带
  get: (url, params, isToken = true, config = { showLoading: true }) => {
    console.log("url", url, config.showLoading);
    if (config.showLoading === true) {
      showLoading();
    }
    if ((typeof params === "number" && !isNaN(params)) || !params) {
      return service.get(
        urlEnum[url] + params,
        { isToken },
        { config: config }
      );
    } else {
      return service.get(
        urlEnum[url],
        {
          params,
          paramsSerializer: function (params) {
            // console.log(QS.stringify(data, { indices: false }))
            return QS.stringify(params, { indices: false });
          },
        },
        { isToken },
        { config: config }
      );
    }
  },

  // post请求,默认携带token,isToken传false不携带
  post: (url, data = [], isToken = true, config = { showLoading: true }) => {
    if (config.showLoading === true) {
      showLoading();
    }
    return service.post(urlEnum[url], data, { isToken }, { config: config });
  },
  // post请求,默认携带token,isToken传false不携带
  postQuery: (
    url,
    params = "",
    isToken = true,
    config = { showLoading: true }
  ) => {
    if (config.showLoading === true) {
      showLoading();
    }
    const formatParametersArray = [];
    for (let [key, value] of Object.entries(params)) {
      formatParametersArray.push(`${key}=${value}`);
    }
    const formatParameters = formatParametersArray.join("&");
    return service.post(
      urlEnum[url] + "?" + formatParameters,
      { isToken },
      { config: config }
    );
  },
  //delete请求,默认携带token,isToken传false不携带
  delete: (url, data, isToken = true, config = { showLoading: true }) => {
    if (config.showLoading === true) {
      showLoading();
    }
    return service.delete(urlEnum[url] + data, { isToken }, { config: config });
  },
};

// 挂载到Vue上
Plugin.install = (Vue) => {
  Vue.axios = _axios;
  window.axios = _axios;
  Object.defineProperties(Vue.prototype, {
    axios: {
      get() {
        return _axios;
      },
    },
    $axios: {
      get() {
        return _axios;
      },
    },
  });
};

let needLoadingRequestCount = 0;

function showLoading() {
  // console.log(111111)
  store.dispatch("app/setLoading", true);
  needLoadingRequestCount++;
}

function hideLoading() {
  setTimeout(tryCloseLoading, 300);
}

function tryCloseLoading() {
  if (needLoadingRequestCount <= 0) return;
  needLoadingRequestCount--;
  if (needLoadingRequestCount === 0) {
    endLoading();
  }
}

function endLoading() {
  Vue.nextTick(function () {
    // DOM 更新了
    store.dispatch("app/setLoading", false);
  });
}
Vue.use(Plugin);

export default Plugin;
