const getters = {
  sidebar: (state) => state.app.sidebar,
  sidebarList: (state) => state.user.menuList,
  name: (state) => state.user.userInfo.name,
  avatar: (state) => state.user.userInfo.avatar,
  userInfo: (state) => state.user.userInfo,
  personnelIdentity: (state) =>
    state.user.userInfo.personnelIdentity
      ? JSON.parse(state.user.userInfo.personnelIdentity)
      : "",
  totalData: (state) => state.data.TotalData,
  cardList: (state) => state.data.cardList,
  cardList_cost_profit: (state) => state.data.cardList_cost_profit,
  cardList_cost_z: (state) => state.data.cardList_cost_z,
  cardList_cost: (state) => state.data.cardList_cost,
  cardList_profit: (state) => state.data.cardList_profit,
  incomeCompare_pro: (state) => state.data.incomeCompare_pro,
  selectCardList: (state) => state.data.selectCardList,
  isShowLoading: (state) => state.app.isShowLoading,
};
export default getters;
