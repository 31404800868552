import { keepTwo } from '@/utils/filters'
const state = {
  // 交易概览数据
  cardList: [
    {
      id: 1,
      icon: 'icon1',
      title: '实际销售额（万）',
      num: 0,
      unit: '万',
      with: 10, // 同比
      withIsAdd: true, // 是否增加
      ring: 0, // 环比
      ringIsRing: false, // 是否增加
      remarks: '统计周期内，对app内线上支付的订单金额和月结订单金额求和，剔除赠品订单、快捷支付单和发生退款的订单，分为无税和有税两种口径。', // 备注
      isSelect: true,
      isShowCheck: true, // 是否显示勾选项
      lineColor: require("../../assets/sale_slices/bg_xsfx_xse@2x.png"), // 线条颜色
      lineData: [], // 线条数据,\
      asis: [],
      saleMoneyList: [],
      salesAvgList: [],
      salesRadioList: [],
      salesTargetList: [],
      random: 0
    },
    {
      id: 2,
      icon: 'icon2',
      title: '目标销售额（万）',
      num: 0,
      unit: '万',
      with: 0, // 同比
      withIsAdd: false, // 是否增加
      ring: 0, // 环比
      ringIsRing: false, // 是否增加
      remarks: '当前细分筛选对应的销售指标，由线下导入产生，分为无税和有税两种口径。', // 备注
      isSelect: false,
      isShowCheck: false, // 是否显示勾选项
      lineColor: require("../../assets/sale_slices/bg_xsfy_gray_m@2x.png"), // 线条颜色
      lineData: [] // 线条数据
    },
    {
      id: 3,
      icon: 'icon3',
      title: '销售达成率',
      num: 0,
      unit: '万',
      with: 0, // 同比
      withIsAdd: false, // 是否增加
      ring: 0, // 环比
      ringIsRing: false, // 是否增加
      remarks: '实际销售额/目标销售额。', // 备注
      isSelect: false,
      isShowCheck: true, // 是否显示勾选项
      lineColor: require("../../assets/sale_slices/bg_xsfy_gray_m@2x.png"), // 线条颜色
      lineData: [], // 线条数据
      saleGap: ""
    },
    {
      id: 4,
      icon: 'icon11',
      title: '历史销售高点（万）',
      num: 0,
      unit: '单',
      with: 0, // 同比
      withIsAdd: false, // 是否增加
      ring: 0, // 环比
      ringIsRing: false, // 是否增加
      remarks: '当前时间维度对应的历史最高销售额。', // 备注
      isSelect: false,
      isShowCheck: true, // 是否显示勾选项
      lineColor: require("../../assets/sale_slices/bg_xsfy_gray_m@2x.png"), // 线条颜色
      lineData: [], // 线条数据,
      historySalesTopDate: ""
    },

  ],
  cardList_cost: [
    {
      id: 1,
      icon: 'icon1',
      title: '实际成本额（万）',
      num: 0,
      unit: '万',
      with: 10, // 同比
      withIsAdd: true, // 是否增加
      ring: 0, // 环比
      ringIsRing: false, // 是否增加
      remarks: '统计周期内，lims实际核算的的订单成本额', // 备注
      isSelect: true,
      isShowCheck: true, // 是否显示勾选项
      lineColor: require("../../assets/sale_slices/bg_xscb_cbe@2x.png"), // 线条颜色
      lineData: [], // 线条数据, 
      random: 0

    },
    {
      id: 2,
      icon: 'icon2',
      title: '标准成本额（万）',
      num: 0,
      unit: '万',
      with: 0, // 同比
      withIsAdd: false, // 是否增加
      ring: 0, // 环比
      ringIsRing: false, // 是否增加
      remarks: '统计周期内，对订单中的检测项目标准成本求和', // 备注
      isSelect: false,
      isShowCheck: false, // 是否显示勾选项
      lineColor: require("../../assets/sale_slices/bg_xsfy_gray_m@2x.png"), // 线条颜色
      lineData: [], // 线条数据
      standardDeviation: ""
    },
    {
      id: 4,
      icon: 'icon11',
      title: '实际成本率',
      num: 0,
      unit: '单',
      with: 0, // 同比
      withIsAdd: false, // 是否增加
      ring: 0, // 环比
      ringIsRing: false, // 是否增加
      remarks: '实际成本额/实际销售额', // 备注
      isSelect: false,
      isShowCheck: true, // 是否显示勾选项
      lineColor: require("../../assets/sale_slices/bg_xsfy_gray_m@2x.png"),// 线条颜色
      lineData: [] // 线条数据
    }

  ],
  cardList_cost_profit: [
    {
      id: 1,
      icon: 'icon1',
      title: '实际毛利（万）',
      num: 0,
      unit: '万',
      realityIncome: "",
      realityCost: 10, // 实际成本
      remarks: '实际销售额-实际成本额，销售额为无税口径', // 备注
      lineColor: require("../../assets/sale_slices/bg_xslr_sjlr_l@2x.png"), // 线条颜色
      lineData: [],
      random: 0, // 线条数据,\
      toUrl: require("../../assets/sale_slices/icon_leftnav_xsfx_green@2x.png"),


    },
    {
      id: 2,
      icon: 'icon2',
      title: '实际毛利率',
      num: 0,
      grossProfitMargin: 0, // 实际毛利率
      remarks: '实际毛利/实际销售额，销售额为无税口径', // 备注
      lineColor: require("../../assets/sale_slices/bg_xsfy_gray_m@2x.png"), // 线条颜色
      lineData: [] // 线条数据
    },

  ],
  cardList_cost_z: [
    {
      id: 1,
      icon: 'icon1',
      title: '实际费用额（万）',
      num: 0,
      unit: '万',
      realityIncome: "",
      realityCost: 10, // 实际成本
      remarks: '统计周期内的实际费用求和', // 备注
      lineColor: require("../../assets/sale_slices/bg_xslr_sjlr_l@2x.png"), // 线条颜色
      lineData: [], // 线条数据,\
      toUrl: require("../../assets/sale_slices/icon_leftnav_xsfx_green@2x.png"),
      with: "",
      ring: '',
      random: 0
    },
    {
      id: 2,
      icon: 'icon2',
      title: '预算费用额（万）',
      num: 0,
      grossProfitMargin: 0, // 实际毛利率
      remarks: '统计周期内的预算费用求和', // 备注
      lineColor: require("../../assets/sale_slices/bg_xsfy_gray_m@2x.png"), // 线条颜色
      lineData: [], // 线条数据
      saleGap: 0,
      gapName: ""
    },
    {
      id: 3,
      icon: 'icon2',
      title: '实际费用率',
      num: 0,
      grossProfitMargin: 0, // 实际毛利率
      remarks: '实际费用额/实际销售额', // 备注
      lineColor: require("../../assets/sale_slices/bg_xsfy_gray_m@2x.png"), // 线条颜色
      lineData: [] // 线条数据
    },
    {
      id: 4,
      icon: 'icon2',
      title: '预算费用率',
      num: 0,
      saleGap: "",
      gapName: "",
      grossProfitMargin: 0, // 实际毛利率
      remarks: '预算费用额/目标销售额', // 备注
      lineColor: require("../../assets/sale_slices/bg_xsfy_gray_m@2x.png"), // 线条颜色
      lineData: [] // 线条数据
    },
    {
      id: 5,
      icon: 'icon2',
      title: '固定费用额（万）',
      num: 0,
      saleGap: "",
      gapName: "固定费用率",
      grossProfitMargin: 0, // 实际毛利率
      remarks: '人头固定费用', // 备注
      lineColor: require("../../assets/sale_slices/bg_xsfy_gray_m@2x.png"), // 线条颜色
      lineData: [] // 线条数据
    },
    {
      id: 6,
      icon: 'icon2',
      title: '变动费用额（万）',
      num: 0,
      gapName: "",
      saleGap: "",
      grossProfitMargin: 0, // 实际毛利率
      remarks: '除人头固定费用外，其他的都是变动费用额', // 备注
      lineColor: require("../../assets/sale_slices/bg_xsfy_gray_m@2x.png"), // 线条颜色
      lineData: [] // 线条数据
    },

  ],
  cardList_profit: [
    {
      id: 1,
      icon: 'icon1',
      title: '实际利润（万）',
      num: 0,
      unit: '万',
      realityIncome: "",
      realityCost: 10, // 实际成本
      remarks: '实际销售额-实际费用额', // 备注
      lineColor: require("../../assets/sale_slices/bg_xslr_sjlr_l@2x.png"), // 线条颜色
      lineData: [], // 线条数据,\
      toUrl: require("../../assets/sale_slices/icon_leftnav_xsfx_green@2x.png"),
      with: "",
      ring: '',
      actualSaleAmount: "",
      actualCostAmount: '',
      random: 0,


    },
    {
      id: 2,
      icon: 'icon2',
      title: '目标利润（万）',
      num: 0,
      grossProfitMargin: 0, // 实际毛利率
      remarks: '目标销售额-预算费用额', // 备注
      lineColor: require("../../assets/sale_slices/bg_xsfy_gray_m@2x.png"), // 线条颜色
      lineData: [], // 线条数据
      saleGap: 0,
      gapName: "目标利润率"
    },
    {
      id: 3,
      icon: 'icon2',
      title: '利润达成率',
      num: 0,
      gapName: '利润缺口',
      grossProfitMargin: 0, // 实际毛利率
      remarks: '实际利润/目标利润', // 备注
      lineColor: require("../../assets/sale_slices/bg_xsfy_gray_m@2x.png"), // 线条颜色
      lineData: [], // 线条数据
      profit_gap: ''

    },
    {
      id: 4,
      icon: 'icon2',
      title: '实际利润率',
      num: 0,
      saleGap: "",
      gapName: "利润率缺口",
      grossProfitMargin: 0, // 实际毛利率
      remarks: '实际利润/实际销售额', // 备注
      lineColor: require("../../assets/sale_slices/bg_xsfy_gray_m@2x.png"), // 线条颜色
      lineData: [] // 线条数据
    },
  ],

  incomeCompare_pro: {
    dateStrList: [],
    deptNameList: [],
    subsetList: [],
    totalAmountList: []
  },


  selectCardList: [
    {
      icon: 'icon1',
      title: '销售总额',
      unit: '万',
      remarks: '统计周期内，对app支付的订单金额和月结订单金额求和，剔除赠送单、快捷支付订单以及当天退款的订单。',
      isSelect: true,
      lineData: []
    },
    {
      icon: 'icon5',
      title: '销售订单量',
      unit: '单',
      remarks: '销售订单数，统计周期内，按订单号去重统计，剔除赠送单、快捷支付订单以及当天退款的订单。',
      isSelect: false,
      lineData: []
    },
    {
      icon: 'icon8',
      title: '在岗销售人数',
      unit: '人',
      remarks: '统计周期内，钉钉组织架构在岗人数。',
      isSelect: false,
      lineData: []
    },
    {
      icon: 'icon6',
      title: '离职销售人数',
      unit: '人',
      remarks: '统计周期内，钉钉组织架构的离职人数。',
      isSelect: false,
      lineData: []
    },
    {
      icon: 'icon9',
      title: '开单销售人数',
      unit: '人',
      remarks: '统计周期内，app支付的订单和月结订单，剔除赠送单、快捷支付订单以及当天退款的订单，其开单销售id进行去重统计。',
      isSelect: false,
      lineData: []
    },
    {
      icon: 'icon10',
      title: '未开单销售人数',
      unit: '人',
      remarks: '统计周期内，无app支付的订单和月结订单(剔除赠送单、快捷支付订单以及当天退款的订单）生成的，其开单销售id进行去重统计。',
      isSelect: false,
      lineData: []
    }
  ]
}

const mutations = {
  // 赋值交易概览数据
  SET_TOTAL_DATA: (state, data) => {
    // 给卡片赋值
    // 销售总额
    state.cardList[0].num = keepTwo(data.totalSales) // 销售总额
    state.cardList[0].with = keepTwo(data.totalSalesYearRatio) // 销售总额同比去年
    state.cardList[0].withIsAdd = data.totalSalesYearRatio >= 0 ? true : false // 同比去年是否增加
    state.cardList[0].ring = keepTwo(data.totalSalesRingRatio) // 销售总额环比上期
    state.cardList[0].ringIsRing = data.totalSalesRingRatio >= 0 ? true : false // 环比上期是否增加

    state.cardList[0].lineData = data.axisMap || { asis: [], serisaleMoneyListes: [], salesAvgList: [], salesRadioList: [], salesTargetList: [] } // 销售总额数据
    state.cardList[0].random = Math.random()
    // state.cardList[0].serisaleMoneyListes = data.axisMap.serisaleMoneyListes
    // state.cardList[0].salesAvgList = data.axisMap.salesAvgList 
    // state.cardList[0].salesRadioList = data.axisMap.salesRadioList 
    // state.cardList[0].salesTargetList = data.axisMap.salesTargetList



    // 销售目标总额
    state.cardList[1].num = keepTwo(data.totalSalesTarget) // 销售目标总额
    // 已认领的销售目标额

    state.cardList[2].num = keepTwo(data.targetAchievementRate)// 已认领的销售目标额
    state.cardList[2].saleGap = keepTwo(data.salesDiff)//销售缺口
    state.cardList[2].lineData = data.salesTargetTotalMap || { xAxis: [], series: [], ratio: [] }  // 已认领目标额数据
    // 历史销售高点
    state.cardList[3].num = keepTwo(data.historySalesTop) // 历史销售高点
    state.cardList[3].with = keepTwo(data.transactionOrderVolumeYearRatio) // 成交单量同比去年
    state.cardList[3].withIsAdd = data.transactionOrderVolumeYearRatio >= 0 ? true : false // 同比去年是否增加
    state.cardList[3].ring = keepTwo(data.transactionOrderVolumeRingRatio) // 成交单量环比上期
    state.cardList[3].ringIsRing = data.transactionOrderVolumeRingRatio >= 0 ? true : false // 环比上期是否增加
    state.cardList[3].lineData = data.saleOrderNumberTotalMap || { xAxis: [], series: [], ratio: [] }  // 成交单量数据
    state.cardList[3].historySalesTopDate = data.historySalesTopDate //历史最高点
    state.cardList[3].timeType = data.timeType //查询日期纬度


  },
  SET_TOTAL_DATA_COST: (state, data) => {
    state.cardList_cost[0].num = data.realityCost // 实际成本总额
    state.cardList_cost[0].chartLabels = data.chartLabels  // 成本
    state.cardList_cost[0].dateList = data.dateList  // 成本
    state.cardList_cost[0].trendDataList = data.trendDataList  // 成本
    state.cardList_cost[0].random = Math.random()




    // 标准成本
    state.cardList_cost[1].num = data.standardCost // 标准成本总额
    state.cardList_cost[1].historySalesTopDate = data.standardCostRate // 标准成本率
    state.cardList_cost[1].standardDeviation = data.standardDeviation //额偏差 


    // 已认领的销售目标额

    // state.cardList_cost[2].num = data.standardCost // 标准成本
    // state.cardList_cost[2].salesDiff = data.salesDiff//销售缺口
    // state.cardList_cost[2].lineData = data.salesTargetTotalMap || {xAxis: [],series: [],ratio: []}  // 已认领目标额数据
    // 历史销售高点
    state.cardList_cost[2].num = data.realityCostRate // 实际成本率
    state.cardList_cost[2].with = data.transactionOrderVolumeYearRatio // 成交单量同比去年
    state.cardList_cost[2].withIsAdd = data.transactionOrderVolumeYearRatio >= 0 ? true : false // 同比去年是否增加
    state.cardList_cost[2].ring = data.transactionOrderVolumeRingRatio // 成交单量环比上期
    state.cardList_cost[2].ringIsRing = data.transactionOrderVolumeRingRatio >= 0 ? true : false // 环比上期是否增加
    state.cardList_cost[2].lineData = data.saleOrderNumberTotalMap || { xAxis: [], series: [], ratio: [] }  // 成交单量数据
    state.cardList_cost[2].historySalesTopDate = data.historySalesTopDate //历史最高点
    state.cardList_cost[2].timeType = data.timeType //查询日期纬度
  },
  SET_TOTAL_DATA_COST_PROFIT: (state, data) => {
    state.cardList_cost_profit[0].num = data.realityGrossProfit // 实际毛利总额
    state.cardList_cost_profit[0].realityIncome = data.realityIncome // 实际毛利总额
    state.cardList_cost_profit[0].realityCost = data.realityCost //实际成本额
    state.cardList_cost_profit[0].random = Math.random()

    state.cardList_cost_profit[0].chartLabels = data.chartLabels  // 成本
    state.cardList_cost_profit[0].dateList = data.dateList  // 成本
    state.cardList_cost_profit[0].trendDataList = data.trendDataList  // 成本

    // 实际毛利率 
    state.cardList_cost_profit[1].num = data.grossProfitMargin // 实际毛利率 
  },
  SET_TOTAL_DATA_COST_Z: (state, data) => {
    state.cardList_cost_z[0].num = keepTwo(data.summary.actualCostAmount) // 实际费用额
    state.cardList_cost_z[0].with = keepTwo(data.summary.yearOnYearRatio) // 同比
    state.cardList_cost_z[0].ring = keepTwo(data.summary.monthOnMonthRatio) // 环比

    state.cardList_cost_z[0].chartLabels = data.chartLabels  // 成本
    state.cardList_cost_z[0].dateList = data.dateList  // 成本
    state.cardList_cost_z[0].trendDataList = data.trendDataList  // 成本

    state.cardList_cost_z[0].random = Math.random()  // 随机

    // 预算费用额
    state.cardList_cost_z[1].num = keepTwo(data.summary.budgetCostAmount) // 预算费用额 
    state.cardList_cost_z[1].saleGap = keepTwo(data.summary.costAchievementRatio) // 费用达成率 
    state.cardList_cost_z[1].gapName = '费用达成率' // 费用达成率 



    state.cardList_cost_z[2].num = keepTwo(data.summary.actualCostRatio) // 实际费用率 



    state.cardList_cost_z[3].num = keepTwo(data.summary.budgetCostRatio) // 预算费用率
    state.cardList_cost_z[3].saleGap = keepTwo(data.summary.costRatioGap) // 费用率缺口 
    state.cardList_cost_z[3].gapName = '费用率缺口' // 费用达成率 


    state.cardList_cost_z[4].num = keepTwo(data.summary.fixedCostAmount) // 固定费用额
    state.cardList_cost_z[4].saleGap = keepTwo(data.summary.fixedCostRatio) // 固定费用率 
    state.cardList_cost_z[4].gapName = '固定费用率' // 固定费用率 


    state.cardList_cost_z[5].num = keepTwo(data.summary.changedCostAmount) // 变动费用额
    state.cardList_cost_z[5].saleGap = keepTwo(data.summary.changedCostRatio) // 变动费用率
    state.cardList_cost_z[5].gapName = '变动费用率' // 变动费用率  



  },
  SET_COSTPROFIT: (state, data) => {
    state.cardList_profit[0].num = data.summary.actualProfitAmount // 实际利润
    state.cardList_profit[0].with = data.summary.yearOnYearRatio // 同比
    state.cardList_profit[0].ring = data.summary.monthOnMonthRatio // 环比
    state.cardList_profit[0].actualSaleAmount = data.summary.actualSaleAmount // 实际销售额
    state.cardList_profit[0].actualCostAmount = data.summary.actualCostAmount // 实际费用额

    state.cardList_profit[0].chartLabels = data.chartLabels  // 
    state.cardList_profit[0].dateList = data.dateList  // 目标利润率
    state.cardList_profit[0].trendDataList = data.trendDataList  // 利润缺口
    state.cardList_profit[0].random = Math.random()  // 随机

    // 预算费用额
    state.cardList_profit[1].num = data.summary.targetProfitAmount // 目标利润 
    state.cardList_profit[1].saleGap = data.summary.targetProfitRatio // 目标利润率 



    state.cardList_profit[2].num = data.summary.profitAchievementRatio // 利润达成率 
    state.cardList_profit[2].profit_gap = data.summary.profitGap // 利润缺口 


    state.cardList_profit[3].num = data.summary.actualProfitRatio // 实际利润率
    state.cardList_profit[3].saleGap = data.summary.profitRatioGap // 费用率缺口 

  },



  PRODUCTINCOME: (state, data) => {
    state.incomeCompare_pro.dateStrList = data.dateStrList
    state.incomeCompare_pro.deptNameList = data.deptNameList
    state.incomeCompare_pro.subsetList = data.subsetList
    state.incomeCompare_pro.totalAmountList = data.totalAmountList
  },
  // 赋值交易分析数据
  SET_FX_DATA: (state, data) => {
    let array1 = [] // 销售总额
    let array2 = [] // 订单量
    let array3 = [] // 在岗销售人数
    let array4 = [] // 离职人数
    let array5 = [] // 开单销售人数
    let array6 = [] // 未开单销售人数
    for (let i = 0; i < data.length; i++) {
      for (let j in data[i]) {
        array1.push({
          id: j,
          lineColor: '#' + ('00000' + (Math.random() * 0x1000000 << 0).toString(16)).substr(-6),
          obj: data[i][j]['saleMoney']
        })
        array2.push({
          id: j,
          lineColor: '#' + ('00000' + (Math.random() * 0x1000000 << 0).toString(16)).substr(-6),
          obj: data[i][j]['saleOrder']
        })
        array3.push({
          id: j,
          lineColor: '#' + ('00000' + (Math.random() * 0x1000000 << 0).toString(16)).substr(-6),
          obj: data[i][j]['onJob']
        })
        array4.push({
          id: j,
          lineColor: '#' + ('00000' + (Math.random() * 0x1000000 << 0).toString(16)).substr(-6),
          obj: data[i][j]['quit']
        })
        array5.push({
          id: j,
          lineColor: '#' + ('00000' + (Math.random() * 0x1000000 << 0).toString(16)).substr(-6),
          obj: data[i][j]['bill']
        })
        array6.push({
          id: j,
          lineColor: '#' + ('00000' + (Math.random() * 0x1000000 << 0).toString(16)).substr(-6),
          obj: data[i][j]['notBill']
        })
      }
    }
    // 销售总额
    state.selectCardList[0].lineData = array1
    // 开单客户数
    state.selectCardList[1].lineData = array2
    // 在岗销售人数
    state.selectCardList[2].lineData = array3
    // 入职销售人数
    state.selectCardList[3].lineData = array4
    // 开单销售人数
    state.selectCardList[4].lineData = array5
    // 未开单销售人数
    state.selectCardList[5].lineData = array6
  }
}

const actions = {
  // 赋值交易概览数据方法
  setTotalData({ commit }, data) {
    commit('SET_TOTAL_DATA', data)
  },
  setTotalDataCost({ commit }, data) {
    commit('SET_TOTAL_DATA_COST', data)
  },
  setTotalDataCostProfit({ commit }, data) {
    commit('SET_TOTAL_DATA_COST_PROFIT', data)
  },
  setTotalDataCostZ({ commit }, data) {
    commit('SET_TOTAL_DATA_COST_Z', data)
  },
  setIncomeProduct({ commit }, data) {
    commit('PRODUCTINCOME', data)
  },
  setProfit({ commit }, data) {
    commit('SET_COSTPROFIT', data)
  },
  // 赋值交易分析数据
  setFxData({ commit }, data) {
    commit('SET_FX_DATA', data)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}