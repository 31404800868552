/*
 * @Description:
 * @Author: cuncai.zhang@simceredx.com
 * @Date: 2023-06-19 16:32:29
 * @LastEditTime: 2023-06-28 13:13:17
 * @LastEditors: cuncai.zhang@simceredx.com
 */

// 处理用户获取的客户权限
export function transformUserPermissions(str) {
  if (!str) {
    return;
  }
  try {
    let parms = JSON.parse(str);
    let resArr = parms.map((item) => item.join("/"));
    return resArr;
  } catch (e) {
    console.log("e", e);
  }
}

// 根据不同层级返回用户权限下的省市区权限
export function customerPermissions(arr, level) {
  const user = JSON.parse(sessionStorage.getItem("userInfo"))?.authDoctor;
  // 省
  const firstLevel = user?.map((item) => {
    let iTarget = item && item?.split("/")[0];
    return iTarget;
  });
  // 市
  const secondLevel = user?.map((item) => {
    let iTarget = item && item?.split("/")[1];
    return iTarget;
  });
  // 区
  const thirdLevel = user?.map((item) => {
    let iTarget = item && item?.split("/")[2];
    return iTarget;
  });

  const obj = {
    1: firstLevel,
    2: secondLevel,
    3: thirdLevel,
  };
  if (!arr && !Array.isArray(arr)) {
    return;
  }
  if (level > 3) {
    return arr;
  }
  const result = arr.filter((i) => obj[level]?.includes(i["regionCode"]));
  return result;
}

// 根据选择的不同省市区返回相对应权限
export function permissoionArea(arr) {
  const user = JSON.parse(sessionStorage.getItem("userInfo"))?.authDoctor;

  if (!arr && !Array.isArray(arr)) {
    return;
  }
  let a = [];

  for (let j in arr) {
    if (arr[j].length !== 4) {
      for (let i in user) {
        let arrString = arr[j].join("/");
        if (user[i].includes(arrString)) {
          a.push(user[i]);
        }
      }
    } else {
      a.push(arr[j].join("/"));
    }
  }
  return a;
}

// 获取当前日期
export function getNowFormatDate() {
  var date = new Date();
  var seperator1 = "-";
  var seperator2 = ":";
  var year = date.getFullYear();
  var month = date.getMonth() + 1;
  var strDate = date.getDate();
  if (month >= 1 && month <= 9) {
    month = "0" + month;
  }
  if (strDate >= 0 && strDate <= 9) {
    strDate = "0" + strDate;
  }
  var currentdate =
    year +
    seperator1 +
    month +
    seperator1 +
    strDate  
  return currentdate;
}
