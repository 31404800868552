<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'App'
}
</script>

<style>
*{
  user-select:none;
  margin: 0;

}
li{list-style-type:none}
.el-tag.el-tag--info{
  color: #00b54b !important;
  background-color: #3BAF6110 !important;
}
.el-tag.el-tag--info .el-tag__close{
  color: #00b54b !important;
}
.el-button--mini,.el-button--small {
 border-radius: 16px !important;
}
.el-input--small .el-input__inner {
    color:#262626;
}

.el-progress-bar__outer{
  border-radius: 100px !important;
  border-bottom-left-radius:0 !important;
  border-top-left-radius:0!important ;
  background-color: #e9edf0!important;

}
.el-progress-bar__inner{
   border-radius: 100px !important;
  border-bottom-left-radius:0 !important;
  border-top-left-radius:0!important ;
}
.el-tag.el-tag--info .el-tag__close {
    color: #FFFFFF !important;
}
.el-dialog{
  border-radius: 15px !important;

}

</style>