<template>
  <!-- Container布局容器 -->
  <el-container>
    <!-- 左侧Sidebar -->
    <el-aside width="200px">
      <sidebar></sidebar>
    </el-aside>
    <el-container>
      <!-- 右侧header -->
      <el-header>
        <div class="avatar-box" @click="isOpenModel">
          <img class="user-avatar" :src="avatar" />
          <div class="user-name">{{ name }}</div>
          <img class="down-btn" src="@/assets/down.png" />
          <!-- 退出登录弹窗 -->
          <div v-if="isOpenSignBox" class="sign-box" @click="signOut">
            退出登录
          </div>
        </div>
      </el-header>
      <!-- 主区域 -->
      <el-main>
        <transition name="fade-transform" mode="out-in">
          <keep-alive :include="cachedViews">
            <router-view :key="key" />
          </keep-alive>
        </transition>
      </el-main>
    </el-container>
    <!-- 加载中 -->
    <div class="loadingModel" v-if="isShowLoading">
      <i class="el-icon-loading" style="color: #3baf61; margin-left: 200px"></i>
      <div
        style="
          color: #3baf61;
          font-size: 14px;
          margin-top: 10px;
          margin-left: 200px;
        "
      >
        数据加载中...
      </div>
    </div>
  </el-container>
</template>
<script>
import { Sidebar } from "./components";
import { mapGetters } from "vuex";
export default {
  name: "Layout",
  components: {
    Sidebar,
  },
  computed: {
    ...mapGetters(["name", "avatar", "isShowLoading"]),
    // 缓存的页签导航
    cachedViews() {
      return this.$store.state.tagsView.cachedViews;
    },
    key() {
      return this.$route.path;
    },
  },
  data() {
    return {
      isOpenSignBox: false, // 是否显示退出登录弹窗
    };
  },
  methods: {
    // 开关退出登录弹窗
    isOpenModel() {
      this.isOpenSignBox = !this.isOpenSignBox;
    },
    // 退出登录
    signOut() {
      this.isOpenSignBox = false;
      this.$confirm("您确定要退出登录？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.axios.get("NEW_LOGOUT", "").then((result) => {
          this.$store.dispatch("user/logout");
          window.location.replace(result.data);
        });
      });
    },
  },
};
</script>

<style>
.el-container {
  height: 100vh !important;
}
.loadingModel {
  width: "100%";
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 99999;
}
.el-header {
  background: #fff;
  height: 48px !important;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.avatar-box {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-right: 24px;
}

.avatar-box {
  cursor: pointer;
}

.sign-box {
  position: absolute;
  right: -10px;
  bottom: -50px;
  background: #fff;
  padding: 10px;
  font-size: 14px;
  width: 100px;
  text-align: center;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}

.sign-box:hover {
  cursor: pointer;
}

.user-name {
  font-size: 12px;
  color: #595959;
  margin-left: 4px;
}

.user-avatar {
  width: 25px;
  height: 25px;
  border-radius: 50px;
}

.down-btn {
  width: 12px;
  height: 12px;
  margin-left: 4px;
}

.el-aside {
  background: #fff;
  height: 100vh;
  padding: 16px 12px 0 12px;
}

.el-main {
  background: #f0f2f5;
  padding: 24px !important;
}
</style>
