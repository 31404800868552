<template>
  <div class="sideStyle">
    <div class="logo-icon">
      <img src="@/assets/logo.png" />
    </div>
    <div class="menu-box" :key="random">
      <el-menu
        :default-active="$route.path"
        class="el-menu-vertical-demo"
        @open="handleOpen"
        @close="handleClose"
        style="border: none"
      >
        <div v-for="(item, index) in sidebarList" :key="index" class="li_con">
          <!-- 多个子菜单 -->
          <el-submenu
            :index="'c' + index"
            v-if="item.children.length > 1 || item.alwaysShow"
          >
            <template slot="title">
              <img
                alt=""
                class="icon"
                :src="
                  mutiIsActive(item.meta.icon)
                    ? require(`@/assets/menu_icon/${item.children[0].meta.icon}_end.png`)
                    : require(`@/assets/menu_icon/${item.children[0].meta.icon}.png`)
                "
              />

              <span
                class="menu-item-text"
                :class="mutiIsActive(item.meta.icon) ? 'menu-item-active2' : ''"
                >{{ item.meta.title }}</span
              >
            </template>
            <!-- <div > -->
            <el-menu-item
              class="menu-item-text"
              :class="$route.name == i.name ? 'is-active' : ''"
              @click="openPage(i.path)"
              v-for="(i, cindex) in item.children"
              :index="i.meta.title"
              :key="cindex"
              v-if="!i.meta.hideMenu"
            >
              <span class="padTitle">{{ i.meta.title }}</span>
            </el-menu-item>
            <!-- </div> -->
          </el-submenu>

          <!-- 一个子菜单 -->
          <el-menu-item
            v-else
            @click="openPage(item.children[0].path)"
            :class="isActive(item.children[0].name) ? 'menu-item-active' : ''"
          >
            <img
              alt=""
              class="icon"
              :src="
                isActive(item.meta.icon)
                  ? require(`@/assets/menu_icon/${item.children[0].meta.icon}_end.png`)
                  : require(`@/assets/menu_icon/${item.children[0].meta.icon}.png`)
              "
            />
            <span
              class="menu-item-text"
              :class="
                isActive(item.children[0].name) ? 'active-menu-item-text' : ''
              "
              >{{ item.children[0].meta.title }}</span
            >
          </el-menu-item>
        </div>
      </el-menu>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      random: 0,
    };
  },
  computed: {
    ...mapGetters(["sidebarList"]),
  },
  watch: {
    sidebarList: {
      handler(newName, oldName) {
        this.random = Math.random();
        this.sidebarList = newName;
      },
      deep: true,
    },
  },
  mounted() {},
  methods: {
    handleOpen(key, keyPath) {},
    handleClose(key, keyPath) {},
    //含有子菜单是否选中菜单
    mutiIsActive(name) {
      if (this.$route.path.indexOf(name) !== -1) {
        return true;
      } else {
        return false;
      }
    },
    // 是否选中菜单
    isActive(name) {
      if (name == this.$route.name) {
        return true;
      } else {
        return false;
      }
    },
    // 跳转路由
    openPage(path) {
      this.$router.push({ path });
    },
  },
};
</script>

<style scoped lang="scss">
.logo-icon {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 20px;
  img {
    width: 145px;
    height: 28px;
  }
}
.icon {
  width: 20px;
  height: 20px;
}
.li_con {
  margin-bottom: 12px;
}
.menu-box {
  display: flex;
  flex-direction: column;

  .menu-item {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    padding: 6px 0 6px 0;
    box-sizing: border-box;
    .menu-item-box {
      width: 90px;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
    }
  }
  .menu-item-text {
    font-size: 14px;
    color: #262626;
    margin-left: 10px;
    .padTitle {
      margin-left: 14px;
    }
  }
  /deep/ .el-icon-arrow-down:before {
    content: "\e6df";
    color: #262626;
  }
  .active-menu-item-text {
    font-size: 14px;
    color: #fff;
    margin-left: 8px;
    font-weight: bold;
  }
  .menu-item:hover {
    background: #3baf6110;
    cursor: pointer;
  }
  .menu-item-active {
    background: #00b54b;
    color: #fff;
  }
  .menu-item-active2 {
    color: #00b54b;
  }
}

.sideStyle /deep/ .el-menu-item {
  border-radius: 4px;
  height: 40px;
  line-height: 40px;
  // color:"#262626"
}
.sideStyle /deep/ .el-submenu__title {
  border-radius: 4px;
  height: 40px;
  line-height: 40px;
}

.sideStyle /deep/ .el-submenu .el-menu-item {
  height: 40px;
  line-height: 40px;
  padding: 0;
  min-width: 100px;
}
.sideStyle /deep/ .el-menu-item-group .is-active {
  background: #00b54b !important;
  color: #fff;
  span {
    color: #fff;
  }
}
.sideStyle /deep/ .el-menu-item-group .not-active {
  background: #fff;
  color: #000;
  span {
    color: #000;
  }
}
.sideStyle /deep/ .el-menu-item-group__title {
  padding: 0;
}
/deep/ .el-submenu__title:hover {
  background-color: rgba(183, 198, 205, 0.3);
}
/deep/ .el-menu-item:hover {
  outline: none;
  background-color: rgba(183, 198, 205, 0.3);
}
.sideStyle /deep/ .el-submenu .el-menu-item:hover {
  background-color: rgba(183, 198, 205, 0.3);
}

.sideStyle /deep/ .el-submenu .is-active {
  background: #00b54b !important;
  color: #fff;
}
</style>
