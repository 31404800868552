
const state = {
  sidebar: {
    // 控制sidebar折叠开关
    opened: sessionStorage.getItem('openSidebar') == 'true' || sessionStorage.getItem('openSidebar') == undefined ? true : false,
    // 动画
    withoutAnimation: false
  },
  // 标识是移动端还是桌面端
  device: 'desktop',
  isShowLoading: false
}

const mutations = {
  // 折叠sidebar开关
  TOGGLE_SIDEBAR: state => {
    state.sidebar.opened = !state.sidebar.opened
    state.sidebar.withoutAnimation = false
    if(state.sidebar.opened){
      sessionStorage.setItem('openSidebar','true')
    }else{
      sessionStorage.setItem('openSidebar','false')
    }
  },
  // 移动端关闭sidebar
  CLOSE_SIDEBAR: (state, withoutAnimation) => {
    sessionStorage.setItem('openSidebar','false')
    state.sidebar.opened = false
    state.sidebar.withoutAnimation = withoutAnimation
  },
  // 改变设备类型
  TOGGLE_DEVICE: (state, device) => {
    state.device = device
  },
  // 设置大Loading
  SET_LOADING: (state,isShow) => {
    state.isShowLoading = isShow
  }
}

const actions = {
  // 折叠sidebar开关
  toggleSideBar({ commit }) {
    commit('TOGGLE_SIDEBAR')
  },
  // 移动端关闭sidebar
  closeSideBar({ commit }, { withoutAnimation }) {
    commit('CLOSE_SIDEBAR', withoutAnimation)
  },
  // 改变设备类型
  toggleDevice({ commit }, device) {
    commit('TOGGLE_DEVICE', device)
  },
  // 设置大Loading
  setLoading({commit},isShow){
    commit('SET_LOADING', isShow)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}