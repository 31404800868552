const state = {
  visitedViews: [],
  cachedViews: []
}

const mutations = {
  // 初始化页签
  INIT_VISITED_VIEW: (state, tagList) => {
    state.visitedViews = tagList
    // 初始化缓存
    if (state.cachedViews.includes(tagList.name)) return
    // 默认都缓存
    if (!tagList.noCache) {
      state.cachedViews.push(tagList.name)
    }
  },
  // 添加页签
  ADD_VISITED_VIEW: (state, view) => {
    // 已有就不重复添加
    if (state.visitedViews.some(v => v.path === view.path)) return
    // 添加
    state.visitedViews.push({
      path: view.path,
      fullPath: view.fullPath,
      title: view.meta.title || 'no-meta-title',
      name: view.name,
      query: view.query,
      noCache: view.meta.noCache || false
    })
    // 同时添加到本地缓存
    sessionStorage.setItem('tagViewList',JSON.stringify(state.visitedViews))
  },
  // 添加页签缓存
  ADD_CACHED_VIEW: (state, view) => {
    if (state.cachedViews.includes(view.name)) return
    // 默认都缓存
    if (!view.meta.noCache) {
      state.cachedViews.push(view.name)
    }
  },
  // 关闭页签
  DEL_VISITED_VIEW: (state, view) => {
    for (const [i, v] of state.visitedViews.entries()) {
      if (v.path === view.path) {
        state.visitedViews.splice(i, 1)
        break
      }
    }
    sessionStorage.setItem('tagViewList',JSON.stringify(state.visitedViews))
  },
  // 删除页签缓存
  DEL_CACHED_VIEW: (state, view) => {
    const index = state.cachedViews.indexOf(view.name)
    index > -1 && state.cachedViews.splice(index, 1)
  },
  // 关闭其他页签
  DEL_OTHERS_VISITED_VIEWS: (state, view) => {
    state.visitedViews = state.visitedViews.filter(v => {
      return v.meta.affix || v.path === view.path
    })
    sessionStorage.setItem('tagViewList',JSON.stringify(state.visitedViews))
  },
  // 关闭其他页签缓存
  DEL_OTHERS_CACHED_VIEWS: (state, view) => {
    const index = state.cachedViews.indexOf(view.name)
    if (index > -1) {
      state.cachedViews = state.cachedViews.slice(index, index + 1)
    } else {
      state.cachedViews = []
    }
  },
  // 关闭所有页签
  DEL_ALL_VISITED_VIEWS: state => {
    const affixTags = state.visitedViews.filter(tag => tag.meta.affix)
    state.visitedViews = affixTags
    sessionStorage.setItem('tagViewList',JSON.stringify(state.visitedViews))
  },
  // 关闭所有页签缓存
  DEL_ALL_CACHED_VIEWS: state => {
    state.cachedViews = []
  },
  // 更新页签
  UPDATE_VISITED_VIEW: (state, view) => {
    for (let v of state.visitedViews) {
      if (v.path === view.path) {
        v = Object.assign(v, view)
        break
      }
    }
    sessionStorage.setItem('tagViewList',JSON.stringify(state.visitedViews))
  },
  // 清空
  CLEAR_ALL(){
    state.visitedViews = []
    state.cachedViews = []
  }
}

const actions = {
  // 初始化页签
  initVisitedView({ commit }){
    if(sessionStorage.getItem('tagViewList') && JSON.parse(sessionStorage.getItem('tagViewList')).length > 0){
      commit('INIT_VISITED_VIEW', JSON.parse(sessionStorage.getItem('tagViewList')))
    }
  },
  // 添加页签并缓存
  addView({ dispatch }, view) {
    dispatch('addVisitedView', view)
    dispatch('addCachedView', view)
  },
  // 添加页签
  addVisitedView({ commit }, view) {
    commit('ADD_VISITED_VIEW', view)
  },
  // 缓存页签
  addCachedView({ commit }, view) {
    commit('ADD_CACHED_VIEW', view)
  },
  // 关闭当前页签并清除缓存
  delView({ dispatch, state }, view) {
    return new Promise(resolve => {
      dispatch('delVisitedView', view)
      dispatch('delCachedView', view)
      resolve({
        visitedViews: [...state.visitedViews],
        cachedViews: [...state.cachedViews]
      })
    })
  },
  // 删除页签
  delVisitedView({ commit, state }, view) {
    return new Promise(resolve => {
      commit('DEL_VISITED_VIEW', view)
      resolve([...state.visitedViews])
    })
  },
  // 删除页签缓存
  delCachedView({ commit, state }, view) {
    return new Promise(resolve => {
      commit('DEL_CACHED_VIEW', view)
      resolve([...state.cachedViews])
    })
  },
  // 关闭其他页签
  delOthersViews({ dispatch, state }, view) {
    return new Promise(resolve => {
      dispatch('delOthersVisitedViews', view)
      dispatch('delOthersCachedViews', view)
      resolve({
        visitedViews: [...state.visitedViews],
        cachedViews: [...state.cachedViews]
      })
    })
  },
  // 关闭其他页签
  delOthersVisitedViews({ commit, state }, view) {
    return new Promise(resolve => {
      commit('DEL_OTHERS_VISITED_VIEWS', view)
      resolve([...state.visitedViews])
    })
  },
  // 关闭其他页签缓存
  delOthersCachedViews({ commit, state }, view) {
    return new Promise(resolve => {
      commit('DEL_OTHERS_CACHED_VIEWS', view)
      resolve([...state.cachedViews])
    })
  },
  // 关闭所有页签
  delAllViews({ dispatch, state }, view) {
    return new Promise(resolve => {
      dispatch('delAllVisitedViews', view)
      dispatch('delAllCachedViews', view)
      resolve({
        visitedViews: [...state.visitedViews],
        cachedViews: [...state.cachedViews]
      })
    })
  },
  // 关闭所有页签
  delAllVisitedViews({ commit, state }) {
    return new Promise(resolve => {
      commit('DEL_ALL_VISITED_VIEWS')
      resolve([...state.visitedViews])
    })
  },
  // 关闭所有页签缓存
  delAllCachedViews({ commit, state }) {
    return new Promise(resolve => {
      commit('DEL_ALL_CACHED_VIEWS')
      resolve([...state.cachedViews])
    })
  },
  // 更新页签
  updateVisitedView({ commit }, view) {
    commit('UPDATE_VISITED_VIEW', view)
  },
  // 清空
  clearAll({commit}){
    commit('CLEAR_ALL')
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
