import Vue from 'vue'
 
Vue.directive('watermark',(el,binding)=>{
    function addWaterMarker(str,parentNode,font,textColor){// 水印文字，父元素，字体，文字颜色
        var can = document.createElement('canvas');
        parentNode.appendChild(can);
        can.width =200;
        can.height = 150;
        can.style.display = 'none';
        var cans = can.getContext('2d');
        cans.rotate(10* Math.PI / 90);
        cans.font = font || "normal 16px/10px Arial";
        cans.fillStyle = textColor || "rgba(180, 180, 180, 0.3)";
        cans.textAlign = 'left';
        cans.textBaseline = 'Middle';
        cans.fillText(str, can.width / 3, can.height / 2);
        parentNode.style.backgroundImage = "url(" + can.toDataURL("image/png") + ")";
    }
    addWaterMarker(binding.value.text, el, binding.value.font, binding.value.textColor)
})