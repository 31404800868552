import Vue from "vue";
import Router from "vue-router";
import store from "../store";
import { MessageBox } from "element-ui";
import Cookies from "js-cookie";

Vue.use(Router);

import Layout from "@/layout/index.vue";

// 静态路由
export const staticRoutes = [];

/*
 * 带权限控制的动态路由
 * 非根节点只有一个子项需要展示分级可设置 alwaysShow: true
 * 多个子节点一定要指定父级的meta
 * path和子path不要重复
 */

export const dynamicRoutes = [
  {
    // 新首页
    path: "/home",
    component: Layout,
    meta: { title: "首页", icon: "home", auth: "22" },
    children: [
      {
        path: "/home",
        component: () => import("@/views/home/index"),
        name: "home",
        meta: { title: "首页", icon: "home", auth: "22" },
      },
    ],
  },

  {
    // 老首页
    path: "/ohome",
    component: Layout,
    meta: { title: "首页", icon: "ohome", auth: "1" },
    children: [
      {
        path: "/ohome",
        component: () => import("@/views/oldHome/index"),
        name: "ohome",
        meta: { title: "首页", icon: "ohome", auth: "1" },
      },
    ],
  },
  {
    path: "/sales",
    component: Layout,
    alwaysShow: true,
    meta: { title: "销售分析", icon: "sales", auth: "2", noCache: false },
    children: [
      {
        path: "/sales",
        component: () => import("@/views/sales/income/index"),
        name: "sales",
        meta: { title: "销售收入", icon: "sales", auth: "3" },
      },
      {
        path: "/sales_service",
        component: () => import("@/views/sales/service/index"),
        name: "sales_service",
        meta: { title: "销售成本", icon: "sales", auth: "4" },
      },
      {
        path: "/sales_cost",
        component: () => import("@/views/sales/cost/index"),
        name: "sales_cost",
        meta: { title: "销售费用", icon: "sales", auth: "5" },
      },

      {
        path: "/sales_gross_grofit",
        component: () => import("@/views/sales/grossProfit/index"),
        name: "sales_gross_grofit",
        meta: { title: "销售毛利", icon: "sales", auth: "6" },
      },
      {
        path: "/sales_profit",
        component: () => import("@/views/sales/profit/index"),
        name: "sales_profit",
        meta: { title: "销售利润", icon: "sales", auth: "7" },
      },
      {
        path: "/sales_order_analysis",
        component: () => import("@/views/sales/orderAnalysis/index"),
        name: "sales_order_analysis",
        meta: { title: "订单分析", icon: "sales", auth: "8" },
      },
      // {
      //   path: '/human',
      //   component: () => import('@/views/human/index'),
      //   name: 'human',
      //   meta: { title: '人力专题', icon: 'human', auth: '3' }
      // }
    ],
  },
  {
    path: "/customer_manage",
    component: Layout,
    alwaysShow: true,
    meta: { title: "客户管理", icon: "customer", auth: "14", noCache: false },
    children: [
      {
        path: "/customer_manage",
        component: () => import("@/views/customer/index"),
        name: "analyze",
        meta: { title: "客户分析", icon: "customer", auth: "15" },
      },
      {
        path: "/customer_company",
        component: () => import("@/views/customer/compnay/index"),
        name: "company",
        meta: { title: "客户分析-公司", icon: "customer", auth: "21" },
      },
      {
        path: "/customer_export",
        component: () => import("@/views/customer/export"),
        name: "export",
        meta: { title: "导出列表", icon: "customer", auth: "16" },
      },
    ],
  },
  {
    path: "/area",
    component: Layout,
    meta: { title: "大区管理", icon: "area", auth: "11" },
    children: [
      {
        path: "/area",
        component: () => import("@/views/area/deptMange/index"),
        name: "大区关联",
        meta: { title: "大区关联", icon: "area", auth: "12" },
      },
      {
        path: "/area_region",
        component: () => import("@/views/area/region/index"),
        name: "地区关联",
        meta: { title: "地区关联", icon: "area", auth: "12" },
      },
      {
        path: "/area_productRelation",
        component: () => import("@/views/area/productRelation/index"),
        name: "产品关联",
        meta: { title: "产品关联", icon: "area", auth: "20" },
      },
      {
        path: "/area_productRelation_edit/:code",
        component: () => import("@/views/area/productRelation/detail"),
        name: "产品关联编辑",
        meta: {
          title: "产品关联编辑",
          icon: "area",
          auth: "20",
          hideMenu: true,
        },
      },
    ],
  },
  {
    path: "/auth",
    component: Layout,
    alwaysShow: false,
    meta: { title: "权限管理", icon: "auth", auth: "9", noCache: false },
    children: [
      {
        path: "/auth",
        component: () => import("@/views/auth/role/index"),
        name: "角色管理",
        meta: { title: "角色管理", icon: "auth", auth: "20" },
      },
      {
        path: "/auth_detail/:id",
        component: () => import("@/views/auth/role/addRole"),
        name: "创建角色",
        meta: { title: "创建角色", icon: "auth", auth: "20", hideMenu: true },
      },
      {
        path: "/auth_account",
        component: () => import("@/views/auth/account/index"),
        name: "auth_account",
        meta: { title: "账号管理", icon: "auth", auth: "10" },
      },
    ],
  },
  {
    path: "/import",
    component: Layout,
    meta: { title: "数据导入", icon: "import", auth: "11" },
    children: [
      {
        path: "/import",
        component: () => import("@/views/dataImport/index"),
        name: "import",
        meta: { title: "数据导入", icon: "import", auth: "12" },
      },
      // {
      //   path: '/auth_detail',
      //   component: () => import('@/views/auth/detail'),
      //   name: 'auth',
      //   meta: { title: '权限详情', icon: 'auth', auth: '10' }
      // }
    ],
  },
  {
    path: "/export",
    component: Layout,
    meta: { title: "数据导出", icon: "import", auth: "23" },
    children: [
      {
        path: "/export",
        component: () => import("@/views/dataexport/index"),
        name: "export",
        meta: { title: "数据导出", icon: "import", auth: "23" },
      },
      // {
      //   path: '/auth_detail',
      //   component: () => import('@/views/auth/detail'),
      //   name: 'auth',
      //   meta: { title: '权限详情', icon: 'auth', auth: '10' }
      // }
    ],
  },
];

// 递归动态路由判断菜单权限
// item是路由
// menu是请求返回的权限组

export const hasPermission = (roles, item) => {
  if (item.meta && item.meta.auth) {
    //includes返回布尔值
    return roles.includes(item.meta.auth);
  } else {
    return true;
  }
};

export const filterRoute = (routes, roles) => {
  let obj = [];
  let tempArr = obj.concat(routes);
  let hasRoutes = tempArr.filter((item) => {
    //item :每一个有权限的路由对象
    // 一级路由
    if (hasPermission(roles, item)) {
      // 子路由
      if (item.children && item.children.length > 0) {
        // 递归判断子路由的子路由
        item.children = filterRoute(item.children, roles);
      }
      return true;
    } else {
      return false;
    }
  });
  return hasRoutes;
};

// 获取静态路由
const getStaticRoutes = () => {
  // console.log('getStaticRoutes++++++++++++++++++++++++',dynamicRoutes)
  if (sessionStorage.getItem("userInfo")) {
    let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    let obj = [...dynamicRoutes];
    let route = filterRoute(dynamicRoutes, userInfo?.authIds?.split(","));
    // console.log("route",route)
    route.forEach((v) => {
      staticRoutes.push(v);
    });
  }
  return staticRoutes;
};

// 创建路由
const createRouter = () =>
  new Router({
    scrollBehavior: () => ({ y: 0 }),
    routes: dynamicRoutes,
  });

// 创建路由
const router = createRouter();

export function resetRouter() {
  const newRouter = createRouter();
  router.matcher = newRouter.matcher;
}

function goFirstRoute(authIds, next) {}

// 全局路由拦截
router.beforeEach((to, from, next) => {
  // 判断是否登录，未登录就重定向到统一登录
  Vue.axios
    .post("GET_LOGIN_USER_INFO", true, { showLoading: false })
    .then((result) => {
      console.log("路由拦截调试", result);
      if (result.data.authIds === "") {
        MessageBox.confirm("您没有权限，请联系管理员", "提示", {
          confirmButtonText: "确定",
          type: "warning",
          showClose: false,
          showCancelButton: false,
          closeOnClickModal: false,
        }).then(() => {
          Vue.axios.get("NEW_LOGOUT", "").then((result) => {
            store.dispatch("user/logout");
            window.location.replace(result.data);
          });
        });
      }
      if (result.code == 2100) {
        process.env.NODE_ENV === "development"
          ? ""
          : window.location.replace(result.data);
      } else {
        if (result.data?.firstLogin) {
          MessageBox.confirm(
            "您首次登录，还没有配置权限，请联系管理员",
            "提示",
            {
              confirmButtonText: "确定",
              type: "warning",
              showClose: false,
              showCancelButton: false,
              closeOnClickModal: false,
            }
          ).then(() => {
            Vue.axios.get("NEW_LOGOUT", "").then((result) => {
              store.dispatch("user/logout");
              window.location.replace(result.data);
            });
          });
        } else {
          Cookies.set("jobNumber", result.data.jobNumber);
          //判断是否已经缓存过内容
          if (!sessionStorage.getItem("userInfo")) {
            store.dispatch("user/setUserInfo", result.data);
            // console.log(sessionStorage.getItem('menu'))
            let a = JSON.parse(sessionStorage.getItem("menu"))[0];
            next({ ...a, replace: true });
          } else {
            // let a = JSON.parse(sessionStorage.getItem('menu'))
            let info = JSON.parse(sessionStorage.getItem("userInfo"));
            info.authIds = result.data.authIds;
            info.setTargetPermissions = result.data.setTargetPermissions;
            info.marketDataScope = result.data.marketDataScope;
            sessionStorage.setItem("userInfo", JSON.stringify(info));
            const authIds = info.authIds.split(",");
            if (to.path === "/") {
              console.log("authIds", authIds);
              if (authIds.includes("22")) {
                next({ name: "home" });
              } else if (authIds.includes("1")) {
                next({ name: "ohome" });
              } else {
                const firstRoute = dynamicRoutes.filter(
                  (item) => item.meta.auth === authIds[0]
                );
                next({ name: firstRoute[0]["children"][0]["name"] });
              }
            }
            if (!from.name) {
              store.commit("user/SET_SIDEBAR_LIST", []);
              // console.log(result.data.authIds?.split(','),'------------------------------')
              store.dispatch(
                "user/setSidebarList",
                result.data.authIds?.split(",")
              );
            }
            // console.log(123)
            // store.dispatch('user/setSidebarList',result.data.authIds?.split(','))
            // console
            // if(b.length==0){
            //   alert("无权限")
            //   console.log("::::::::")
            //   next({ ...a[0], replace: true })
            // }
          }
        }
        next();
      }
    })
    .catch((error) => {
      // 处理 getJSON 和 前一个回调函数运行时发生的错误
      console.log("发生错误！", error);
    });
});

export default router;
