export function keepTwo(value) {
    // 截取当前数据到⼩数点后三位
    let val = ''
    // console.log(value,"_____________",!isNaN(value) && value != '')
    if (value&&!isNaN(value) && value != ''||value==0) {
        if (value % 1 == 0 && value != 0) {//整数
            val=value+".00"
        }
        else if (value == 0) {
            val = "0"
        }
        else {//小数
            const itemVal = Number(value).toFixed(3)
            val = itemVal.substring(0, itemVal.length - 1)
        }
    } else {
        val=''
    }
    // console.log(val,"_____________")
    return val

}